










import { Vue, Component } from 'vue-property-decorator'
import { mask } from 'vue-the-mask'
import { cepInvalido } from '@/shareds/regras-de-form'

@Component({
	directives: {
		mask,
	},
})
export default class CampoDeCep extends Vue  {
	cepInvalido = cepInvalido
}

