

















import { Vue, Component, Prop } from 'vue-property-decorator'
import type { Usuario } from '@/models'

@Component
export default class AvatarDeUsuario extends Vue {
	@Prop() usuario!: Usuario

	get iniciaisDoNome() {
		return this.usuario.nome
			.replace(/\s(de|da|dos|das)\s/g, ' ')
			.replace(/[^a-zA-Zs]/g, "")
			.split(' ')
			.filter(palavra => !!palavra)
			.map(palavra => palavra[0])
			.join('')
	}
}

