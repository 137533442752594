
































































import StatusDaComanda from '@/components/loja/StatusDaComanda.vue';
import type { ComandaForm } from '@/models/Comanda';
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils';
import { obrigatorio } from '@/shareds/regras-de-form';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';


@Component({
	components: {
		StatusDaComanda,
	},
})
export default class DialogoDeCadastroDeComandas extends Vue {
	@Ref() form!: HTMLFormElement
	@Ref() campoDeNome!: HTMLInputElement
	@Prop({ type: Object, default: criarComanda }) initialValue!: ComandaForm
	@Prop({ type: Boolean, default: false }) disabled!: boolean
	@Prop({ type: Boolean, default: false }) readonly!: boolean

	comanda: ComandaForm = criarComanda()

	mostra = false

	obrigatorio = obrigatorio
	dateTimeToPtBrFormat = dateTimeToPtBrFormat

	@Watch('mostra')
	onChangeMostra(mostra: boolean) {
		if (!mostra) return
		this.comanda = JSON.parse(JSON.stringify(this.initialValue))
		setTimeout(() => {
			this.form.resetValidation()
			setTimeout(() => {
				this.campoDeNome.focus()
			})
		})
	}

	confirmar() {
		if (!this.form.validate()) return
		if(this.comanda.aberto) this.comanda.aguardandoProcessamentoNoCaixa = true
		this.$emit('adicionarComanda', this.comanda)
		this.$emit('updateComanda', this.comanda)
		this.mostra = false
	}

}

function criarComanda(): ComandaForm {
	return {
		id: '',
		identificador: '',
		dataHoraDeAbertura: '',
		aberto: false,
		nome: '',
		descricao: '',
		itemDaComanda: [],
		createdAt: '',
		updatedAt: '',
		taxaDeServico: 0,
		aguardandoProcessamentoNoCaixa: false,
	}
}

