





























import { Vue, Component, Prop, Watch, PropSync } from 'vue-property-decorator'
import type { Marca, Page } from '@/models'
import { FindMarcaUseCase } from '@/usecases'
import axios, { CancelTokenSource } from 'axios'
import { Pageable } from '@/models/Pageable'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
@Component
export default class SeletorMarca extends Vue {
	@Prop({ type: Boolean, default: () => null }) exibirTodasAsTabelas!: boolean
	@PropSync('value', { type: Object, default: null }) marca!: Marca
	findMarcaUseCase = new FindMarcaUseCase()
	busca: null | string = null
	pagina: Page<Marca> | null = null
	marcas: Marca[] = []
	marcasCarregadas = 0
	carregouTodasAsMarcas = false
	carregando = false
	cancelToken: CancelTokenSource | null = null

	async created() {
		await this.buscar()
	}

	async buscar(paginavel: Pageable = {}) {
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}
			this.pagina = await this.findMarcaUseCase.findAll({
				page: this.busca ? -1 : (paginavel.page || 0),
				size: paginavel.size || 20,
				busca: this.busca,
			}, axiosConfig)
			
			this.pagina.content.forEach(marca => {
				this.marcas.push(marca)
			})

			this.marcasCarregadas = this.marcas.length
			this.carregouTodasAsMarcas = this.marcasCarregadas >= this.pagina.totalElements
		} catch (e) {
			if (axios.isCancel(e)) return
			AlertModule.setError(e)
		} finally {
			this.carregando = false
		}
	}

	get marcasFormatadas(): Marca[] {
		return this.marcas 
	}

	@Watch('busca')
	onChangeBusca() {
		this.marcasCarregadas = 0
		this.marcas = []
		this.carregouTodasAsMarcas = false
		this.buscar()
	}

	limpaFiltros() {
		this.carregouTodasAsMarcas = false
		this.marcasCarregadas = 0
	}
}
