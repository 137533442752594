























































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import type { FormEndereco } from '@/models'
import CampoDeCep from '@/components/ui/CampoDeCep.vue'
import { Fragment } from 'vue-fragment'
import { cepInvalido } from '@/shareds/regras-de-form'
import { BuscaCepUseCase } from '@/usecases'

function gerarEnderecoVazio(): FormEndereco {
	return {
		id: null,
		rua: '',
		numero: '',
		cep: '',
		bairro: '',
		complemento: '',
		cidade: '',
		codigoIBGE: '',
		uf: '',
		codigoPais: '1058',
		nomePais: 'Brasil',
	}
}

@Component({
	model: {
		prop: 'endereco',
	},
	components: {
		CampoDeCep,
		Fragment,
	},
})
export default class CamposDeEndereco extends Vue {
	@Prop({ default: () => gerarEnderecoVazio(), type: Object })
	endereco!: FormEndereco
	buscandoCep = false
	errors: string[] = []
	buscaCep = new BuscaCepUseCase()
	
	@Watch('endereco')
	onChangeEndereco(endereco: CamposDeEndereco['endereco']) {
		this.$emit('input', endereco)
	}

	@Watch('endereco.cep')
	async onChangeCep(cep: string) {
		if (!cepInvalido(cep)) return
		if (cep.length < 9) return
		try {
			this.buscandoCep = true
			const data = await this.buscaCep.busca(cep)
			if (data.logradouro) 
				this.endereco.rua = data.logradouro
			this.endereco.cidade = data.localidade
			this.endereco.bairro = data.bairro
			this.endereco.uf = data.uf
			this.endereco.codigoIBGE = data.ibge
		} catch (e: any) {
			this.errors.push(e.message)
			if (!this.endereco.uf) {
				this.endereco.rua = ''
				this.endereco.cidade = ''
				this.endereco.bairro = ''
				this.endereco.uf = ''
				this.endereco.codigoIBGE = ''
			}
		} finally {
			this.buscandoCep = false
		}
	}
}

