


















































import { ParcelasComTaxas } from '@/models'
import type { TipoDePagamento } from '@/models'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class DialogoDeTaxas extends Vue {
	@Prop() tipoDePagamentos!: TipoDePagamento

	mostra = false
	carregando = false

	confirmarLista() {
		this.mostra = false
	}

	mostrar() {
		if(!this.tipoDePagamentos.parcelasComTaxas) return
		this.mostra = true 
		this.tipoDePagamentos.parcelasComTaxas.sort(this.compararPorParcela)
	}

	esconder() {
		this.mostra = false
	}

	compararPorParcela(a: ParcelasComTaxas, b: ParcelasComTaxas) {
		return a.parcelamentos - b.parcelamentos;
	}
}
