












































import { Vue, Component, Watch, PropSync, Ref, Prop } from 'vue-property-decorator'
import { Cliente, Page } from '@/models'
import { FindClienteUseCase } from '@/usecases'
import axios, { CancelTokenSource } from 'axios'
import { Pageable } from '@/models/Pageable'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { formatarCnpjOuCpf } from '@/shareds/formatadores'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
@Component
export default class SeletorDeCliente extends Vue {
	@PropSync('value', { default: null }) cliente!: Cliente | string
	@Prop() grupoEconomicoDaLoja!: string
	findClienteUseCase = new FindClienteUseCase()
	busca: null | string = null
	pagina: Page<Cliente> | null = null
	clientes: Cliente[] = []
	clientesCarregadas = 0
	carregouTodosOsClientes = false
	carregando = false
	cancelToken: CancelTokenSource | null = null
	@Ref() campoDeSelecaoDeCliente!: HTMLInputElement
	formatarCnpjOuCpf = formatarCnpjOuCpf

	async buscar(paginavel: Pageable = {}) {
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}
			this.pagina = await this.findClienteUseCase.find({
				page: this.busca ? (paginavel.page || 0) : 0,
				size: paginavel.size || 20,
				busca: this.busca,
				gruposEconomicosId: this.grupoEconomicoDaLoja ?
					this.grupoEconomicoDaLoja
					:
					UserLoginStore.usuario?.gruposEconomicos.map(grupoEconomico => grupoEconomico.id),
				isOperacaoDeCaixa: !this.grupoEconomicoDaLoja ? false : true,
			}, axiosConfig)
			
			this.pagina.content.forEach(cliente => {
				this.clientes.push(cliente)
			})

			this.clientesCarregadas = this.clientes.length
			this.carregouTodosOsClientes = this.clientesCarregadas >= this.pagina.totalElements
		} catch (e) {
			if (axios.isCancel(e)) return
			AlertModule.setError(e)
		} finally {
			this.carregando = false
			if (this.clientes.length === 0) {
				this.$emit('clienteNaoExiste')
			}
		}
	}

	realizarBusca(busca: string | Cliente) {
		if (typeof busca === 'string') {
			this.busca = busca

			const termoBusca = busca.toLowerCase()
			this.clientes = this.clientes.filter((cliente) => {
				const nome = cliente.razaoSocialOuNome.toLowerCase();
				const cpf = cliente.cnpjOuCpf.toLowerCase();
				return nome.includes(termoBusca) || cpf.includes(termoBusca);
			})
		}
	}

	@Watch('busca')
	onChangeBusca() {
		this.clientesCarregadas = 0
		this.clientes = []
		this.carregouTodosOsClientes = false
		this.buscar()
		this.pagina = null
	}

	limpaFiltros() {
		this.carregouTodosOsClientes = false
		this.clientesCarregadas = 0
	}

	focus() {
		this.campoDeSelecaoDeCliente.focus()
	}
}
