
























import { Vue, Component, PropSync, Prop } from 'vue-property-decorator'
import { TipoDePagamento } from '@/models'
import DataTableDeCrud from '@/components/ui/DataTableDeCrud.vue'
import DialogoDeTipoDePagamento, { criarTipoDePagamento } from './DialogoDeTipoDePagamento.vue'
import { v4 as uuidv4 } from 'uuid'

@Component({
	components: {
		DataTableDeCrud,
		DialogoDeTipoDePagamento,
	},
})
export default class TableDeTiposDePagamento extends Vue {
	@PropSync('value') tipos!: TipoDePagamento[]
	@Prop({ type: Boolean, default: false}) mostraPagamentoTEF!: boolean

	headers = [
		{ text: 'Nome', value: 'nome' },
		{ text: 'Forma', value: 'formaDePagamento' },
		{ text: 'Parcelas', value: 'displayParcelas' },
	]

	carregando = false

	busca = ''

	mostraDialogo = false

	get temConsignado() {
		return this.tipos.some(tipo => tipo.formaDePagamento === 'Consignado');
	}

	get temDemonstracao() {
		return this.tipos.some(tipo => tipo.formaDePagamento === 'Demonstração');
	}

	tipoDePagamento: TipoDePagamento | null = null

	get tiposDePagamentoFormatados(): DisplayTiposDePagamento[] {
		return this.tipos.map(
			tipo => ({
				...tipo,
				displayParcelas: tipo.parcelasComTaxas != null
					? tipo.parcelasComTaxas.map(item => item.parcelamentos).sort((pA, pB) =>{
						if( pA < pB) return -1
						if( pA > pB) return 1
						return 0
					}).join(', ')
					: 'Á vista',
			}),
		) 
	
	}

	editar(indice: number) {
		const tipo = this.tipos[indice]
		if (!tipo) return
		this.tipoDePagamento = tipo
		this.mostraDialogo = true
	}

	criar() {
		this.tipoDePagamento = criarTipoDePagamento()
		this.mostraDialogo = true
	}

	salvar(tipo: TipoDePagamento) {
		if (!tipo.id) {
			this.tipos.push({
				...tipo,
				id: uuidv4(),
			})
			return
		}
		const indice = this.tipos.findIndex(
			({ id }) => id === tipo.id,
		)
		if (indice === -1) return
		this.tipos.splice(indice, 1, tipo)
	}

	remover(indice: number) {
		this.tipos.splice(indice, 1)
		this.$emit('input', [
			...this.tipos,
		])
	}
}

interface DisplayTiposDePagamento extends TipoDePagamento {
	displayParcelas: string
}

