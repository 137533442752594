

























































import { Vue, Component, PropSync, Prop, Ref } from 'vue-property-decorator'
import { UsuariosModule } from '@/store/vuex/usuario/UsuariosStore'
import { ConfiguracaoDeNotificacaoPorEmail } from '@/models'
import { DeleteLojaUseCase, SaveLojaUseCase } from '@/usecases'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import DialogoDeConfiguracaoDeNotificacaoPorEmail from '../loja/DialogoDeConfiguracaoDeNotificacaoPorEmail.vue'
import Confirmacao from '../ui/Confirmacao.vue'

@Component({
	components: {
		DialogoDeConfiguracaoDeNotificacaoPorEmail,
		Confirmacao,
	},
})
export default class ListaDeNotificacoesPorConfigPorEmail extends Vue  {
	@PropSync('value') configuracoesDeNotificacaoPorEmail!: ConfiguracaoDeNotificacaoPorEmail[]
	@Prop() lojaId!: string
	@Prop() modulo!: 'Estoque' | 'Vendas' | 'Nota em Transito'
	@Ref() dialogo!: DialogoDeConfiguracaoDeNotificacaoPorEmail

	salvando = false
	removendo = false

	UsuariosModule = UsuariosModule
	saveUseCase = new SaveLojaUseCase()
	deleteUseCase = new DeleteLojaUseCase()

	get configuracoesDeNotificacaoPorEmailPorModulo() {
		if (!this.configuracoesDeNotificacaoPorEmail) return []

		return this.configuracoesDeNotificacaoPorEmail.filter(config => config.modulo === this.modulo)
	}

	async remover(indice: number) {
		try {
			const idConfig = this.configuracoesDeNotificacaoPorEmail[indice].id
			if (idConfig !== null) {
				await this.deleteUseCase.deletarConfigDeNotificacaoPorEmail(idConfig)
			}

			this.configuracoesDeNotificacaoPorEmail.splice(indice, 1)
		} catch(error) {
			AlertModule.setError(error)
		}
	}

	async atualizarConfiguracao(configuracaoDeNotificacaoPorEmail: ConfiguracaoDeNotificacaoPorEmail) {
		try {
			this.salvando = true

			const indice = this.configuracoesDeNotificacaoPorEmail.findIndex(config => config.id === configuracaoDeNotificacaoPorEmail.id)

			await this.saveUseCase.salvarConfigDeNotificacaoPorEmail(this.lojaId, configuracaoDeNotificacaoPorEmail)

			if (indice >= 0) {
				this.configuracoesDeNotificacaoPorEmail.splice(indice, 1, configuracaoDeNotificacaoPorEmail)
			} else {
				this.configuracoesDeNotificacaoPorEmail.push(configuracaoDeNotificacaoPorEmail)
			}
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
		}
	}

	abrirDialogo(config: ConfiguracaoDeNotificacaoPorEmail) {
		this.dialogo.mostrar(config)
	}
}

