















































import { Marca, MarcaSalvar } from '@/models';
import { obrigatorio } from '@/shareds/regras-de-form';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import { Component, Prop, Vue } from 'vue-property-decorator';
import SeletorMarca from './SeletorMarca.vue';

@Component({
	components: {
		SeletorMarca,
	},
})
export default class DialogoDeVincularMarcaComLoja extends Vue {
	@Prop() marcasDaloja!: Marca[]
	mostra = false
	listaDeMarcas: MarcaSalvar[] = []

	obrigatorio = obrigatorio

	mostrar() {
		this.mostra = true
	}

	cancelar() {
		this.mostra = false
		this.listaDeMarcas = []
	}

	concluir() {
		this.$emit("update", this.listaDeMarcas.map(m => m.marca))
		this.mostra = false
		this.listaDeMarcas = []
	}

	get marcasListagem() {
		return this.listaDeMarcas
	}

	adicionarParametro(novaMarca: MarcaSalvar, indice: any) {
		let podeAdicionar: boolean | true = true
		
		const marcasDaLista = this.listaDeMarcas.length > 1 ? this.listaDeMarcas.filter(
			marcaLoja =>
				marcaLoja.marca.id === novaMarca.marca.id,
		) : []

		if (marcasDaLista.length > 1) {
			AlertModule.setError(
				'Essa marca já está selecionada',
			)
			podeAdicionar = false
			this.listaDeMarcas.splice(indice, 1)
			return
		}
		

		const marcasDaLoja =  this.marcasDaloja.length > 1 ? this.marcasDaloja.filter(
			marcaLoja =>
				marcaLoja.id === novaMarca.marca.id,
		) : []
		if (marcasDaLoja.length > 0) {
			AlertModule.setError(
				'Essa marca já está vinculada com esta loja',
			)
			this.listaDeMarcas.splice(indice, 1)
			podeAdicionar = false
		}

		if (podeAdicionar) {
			novaMarca.marca.id != null
				? (this.listaDeMarcas[indice] = novaMarca)
				: null
		}
	}

	adicionarNovaMarca() {
		this.listaDeMarcas.push({
			marca: {
				id: '',
				nome: '',
				lojas: [],
			},
		})
	}
}
