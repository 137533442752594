











import type { PontoDeVenda } from "@/models";
import { Vue, Prop, Component } from "vue-property-decorator";

@Component({})
export default class StatusDoPontoDeVenda extends Vue {

	@Prop() pdv!: PontoDeVenda
	@Prop({ default: false }) mostrarDescricao?: boolean

	get descricaoStatus() {
		return `${ this.pdv.aberto ? 'Aberto' : 'Fechado'}`
	}
}

