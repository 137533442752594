















































import { Cliente, ClienteSalvar } from '@/models';
import { obrigatorio } from '@/shareds/regras-de-form';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { criarCliente } from '@/shareds/cliente-shareds';
import SeletorDeCliente from '../../clientes/SeletorDeCliente.vue';
import { FindClienteUseCase } from '@/usecases';

@Component({
	components: {
		SeletorDeCliente,
	},
})
export default class DialogoDeVincularClienteComLoja extends Vue {
	@Prop() clientesDaloja!: Cliente[]
	@Prop() lojaId!: string
	mostra = false
	listaDeCliente: ClienteSalvar[] = []

	findClienteUseCase = new FindClienteUseCase()

	obrigatorio = obrigatorio

	mostrar() {
		this.mostra = true
	}

	cancelar() {
		this.mostra = false
		this.listaDeCliente = []
	}

	async concluir() {
		const itensFormatados = this.listaDeCliente.map(clienteSalvar => clienteSalvar.cliente)
		this.$emit("update:clientes", itensFormatados)
		this.mostra = false
		this.listaDeCliente = []
	}

	get clienteListagem() {
		return this.listaDeCliente
	}

	async adicionarParametro(novoCliente: ClienteSalvar, indice: any) {
		let podeAdicionar: boolean | true = true
		if(!novoCliente && novoCliente == undefined) return 
		const existVinculo = await this.findClienteUseCase.existeVinculoDeClinteELoja(novoCliente.cliente.id, this.lojaId)
		const clienteDaLista = this.listaDeCliente && this.listaDeCliente.length > 1 
			? this.listaDeCliente.filter(
				clienteLoja =>
					clienteLoja.cliente.id === novoCliente.cliente.id,
			) : []

		if (clienteDaLista.length > 1 || existVinculo) {
			AlertModule.setError(
				'Esse cliente já está selecionada',
			)
			podeAdicionar = false
			this.listaDeCliente.splice(indice, 1)
			return
		}
		

		const clientesDaloja = this.clientesDaloja && this.clientesDaloja.length > 1
			? this.clientesDaloja.filter(
				clienteLoja =>
					clienteLoja.id === novoCliente.cliente.id,
			) : []
		if (clientesDaloja.length > 0) {
			AlertModule.setError(
				'Esse cliente já está vinculada com esta loja',
			)
			this.listaDeCliente.splice(indice, 1)
			podeAdicionar = false
		}

		if (podeAdicionar) {
			novoCliente.cliente.id != null
				? (this.listaDeCliente[indice] = novoCliente)
				: null
		}
	}

	adicionarNovoCliente() {
		this.listaDeCliente.push({cliente: criarCliente() as Cliente, disponivelEmCashback: 0})
	}
}
