

























import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import BuscaDeUsuario from './BuscaDeUsuario.vue'
import { Usuario } from '@/models'

@Component({
	components: {
		BuscaDeUsuario,
	},
})
export default class DialogoDeBuscaDeUsuario extends Vue {
	@Prop({ type: Boolean, default: false }) filtrarSomenteUsuariosAtivos!: boolean
	@Prop({ type: Boolean, default: false }) filtrarSomenteVendedorAtivo!: boolean

	usuario: Usuario | null = null
	mostra = false

	@Watch('usuario')
	onChangeUsuario(usuario: Usuario | null){
		if (!usuario) return
		this.$emit('input', usuario)
		this.mostra = false
		this.$nextTick(() => {
			this.usuario = null
		})
	}
}

