










import { Vue, Component } from 'vue-property-decorator'
import { mask } from 'vue-the-mask'
import { cnpjInvalido } from '@/shareds/regras-de-form'

@Component({
	directives: {
		mask,
	},
})
export default class CampoDeCnpj extends Vue  {
	cnpjInvalido = cnpjInvalido
}

