



















































import { TabelaDePreco, TipoDeCliente } from '@/models'
import type { FormLoja } from '@/models'
import { Vue, Component, PropSync } from 'vue-property-decorator'
import { obrigatorio } from '@/shareds/regras-de-form'
import { FindTipoDeClienteUseCase, FindTabelaDePrecoUseCase } from '@/usecases'
import SeletorDeTabelaDePreco from '@/components/tabeladepreco/SeletorDeTabelaDePreco.vue'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'

@Component({
	components: {
		SeletorDeTabelaDePreco,
	},
})
export default class TabDeTabelaDePreco extends Vue {
	@PropSync('value') loja!: FormLoja
	obrigatorio = obrigatorio
	tiposDeClientes: TipoDeCliente[] = []
	tabelasDePreco: TabelaDePreco[] = []
	findTiposDeClientesUseCase = new FindTipoDeClienteUseCase()
	findTabelaDePrecoUseCase = new FindTabelaDePrecoUseCase()
	

	async created() {
		try {
			this.tiposDeClientes = (await this.findTiposDeClientesUseCase.find()).content
		} catch (e) {
			AlertModule.setError(e)
		}
	}

	get tiposDeClientesSelecionados() {
		return this.loja.configuracaoDaLoja.tabelasDePreco
			.map(({ tipoDeCliente }) => tipoDeCliente)
	}

	get temTodosOsTiposDeClientes() {
		return this.tiposDeClientesSelecionados.length === this.tiposDeClientes.length + 1
	}

	opcoesDeTiposDeClientes(tipoAtual: TipoDeCliente | null) {
		return this.tiposDeClientes.filter(
			tipo => tipo.nome === tipoAtual?.nome || !this.tiposDeClientesSelecionados
				.map(grupo => grupo?.nome).includes(tipo.nome),
		)
	}

	get tabelasDePrecosLoja() {
		if (!this.loja.configuracaoDaLoja) return []
		return this.loja.configuracaoDaLoja.tabelasDePreco
	}

	adicionarTabelaDePrecoCliente() {
		if (!this.loja.configuracaoDaLoja) return
		this.loja.configuracaoDaLoja.tabelasDePreco.push({
			id: '',
			tipoDeCliente: null,
			tabelaDePreco: null,
		})
	}

	removerTabelaDePrecoDaLoja(indice: number) {
		if (!this.loja.configuracaoDaLoja) return
		this.loja.configuracaoDaLoja.tabelasDePreco.splice(indice, 1)
	}
}

