












































import { Vue, Component, Prop, PropSync } from 'vue-property-decorator'
import type { Loja } from '@/models'
import ConfirmExclusionDialog from '@/components/ui/ConfirmExclusionDialog.vue'
import DialogoDeCadastroDeComandas from '@/views/application/lojas/DialogoDeCadastroDeComandas.vue'
import StatusDaComanda from './StatusDaComanda.vue'
import type { Comanda } from '@/models/Comanda'

@Component({
	components: {
		ConfirmExclusionDialog,
		DialogoDeCadastroDeComandas,
		StatusDaComanda,
	},
})
export default class CardDeAcoesDaComanda extends Vue {
	@PropSync('value') comanda!: Comanda
	@Prop({ type: Boolean, default: false }) esconderAcoes!: boolean
	@PropSync('loja', { type: Object }) lojaSync?: Loja
}

