























































import { Vue, Component, PropSync, Prop } from 'vue-property-decorator'
import { UsuariosModule } from '@/store/vuex/usuario/UsuariosStore'
import DialogoDeConfiguracaoDeNotificacaoPorLoja from '../loja/DialogoDeConfiguracaoDeNotificacaoPorLoja.vue'
import { ConfiguracaoDeNotificacaoPorUsuario } from '@/models'
import { DeleteLojaUseCase, SaveLojaUseCase } from '@/usecases'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import Confirmacao from '../ui/Confirmacao.vue'

@Component({
	components: {
		DialogoDeConfiguracaoDeNotificacaoPorLoja,
		Confirmacao,
	},
})
export default class ListaDeNotificacoesPorConfigPorUsuario extends Vue  {
	@PropSync('value') configuracoesDeNotificacaoPorLoja!: ConfiguracaoDeNotificacaoPorUsuario[]
	@Prop() lojaId!: string
	@Prop() modulo!: 'Estoque' | 'Vendas' | 'Nota em Transito'

	salvando = false
	removendo = false

	UsuariosModule = UsuariosModule
	saveUseCase = new SaveLojaUseCase()
	deleteUseCase = new DeleteLojaUseCase()

	get configuracoesDeNotificacaoPorLojaPorModulo() {
		if (!this.configuracoesDeNotificacaoPorLoja) return []

		return this.configuracoesDeNotificacaoPorLoja.filter(config => config.modulo === this.modulo)
	}

	async remover(indice: number) {
		try {
			const idConfig = this.configuracoesDeNotificacaoPorLoja[indice].id
			if (idConfig !== null) {
				await this.deleteUseCase.deletarConfigDeNotificacaoPorUsuario(idConfig)
			}

			this.configuracoesDeNotificacaoPorLoja.splice(indice, 1)
		} catch(error) {
			AlertModule.setError(error)
		}
	}

	async atualizarConfiguracao(configuracaoDeNotificacaoPorLoja: ConfiguracaoDeNotificacaoPorUsuario) {
		try {
			this.salvando = true

			const indice = this.configuracoesDeNotificacaoPorLoja.findIndex(config => config.id === configuracaoDeNotificacaoPorLoja.id)

			await this.saveUseCase.salvarConfigDeNotificacaoPorUsuario(this.lojaId, configuracaoDeNotificacaoPorLoja)

			if (indice >= 0) {
				this.configuracoesDeNotificacaoPorLoja.splice(indice, 1, configuracaoDeNotificacaoPorLoja)
			} else {
				this.configuracoesDeNotificacaoPorLoja.push(configuracaoDeNotificacaoPorLoja)
			}
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
		}
	}

	novaConfiguracao() {
		return {
			id: null,
			lojaId: this.lojaId,
			usuario: null,
			modulo: 'Estoque',
			tipoNotificacao: null,
		} as ConfiguracaoDeNotificacaoPorUsuario
	}
}

