

























import { Vue, Component, PropSync, Prop } from 'vue-property-decorator'
import { SerieFiscal } from '@/models/SerieFiscal'
import DataTableDeCrud from '@/components/ui/DataTableDeCrud.vue'
import DialogoFormSerieFiscal from './DialogoFormSerieFiscal.vue'
import { verificarSerieRepetida, verificarSerieFiscalSemPontoDeVendaAssociado } from '@/shareds/loja-shareds'
import { Loja } from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'


@Component({
	components: {
		DataTableDeCrud,
		DialogoFormSerieFiscal,
	},
})
export default class TableDeSeriesFiscais extends Vue {
	@PropSync('value') seriesFiscais!: SerieFiscal[]
	@Prop() loja!: Loja | null

	headers = [
		{ text: 'Ambiente', value: 'ambiente' },
		{ text: 'Série', value: 'serie' },
		{ text: 'Próxima NF', value: 'numeroNota' },
		{ text: 'Tipo', value: 'tipo' },

	]

	carregando = false

	busca = ''

	mostraDialogo = false

	serieFiscal: SerieFiscal | null = null

	get seriesFiscaisFormatadas(): DisplaySerieFiscal[] {
		if (!this.seriesFiscais) return []
		return this.seriesFiscais.map(
			serieFiscal => ({
				...serieFiscal,
				displaySerie: `Série ${serieFiscal.serie}`,
			}),
		)
	}

	get verificarSerieRepetida() {
		return verificarSerieRepetida(this.seriesFiscaisSemAtual)
	}

	get seriesFiscaisSemAtual() {
		return this.seriesFiscais.filter(
			serieFiscal => !this.serieFiscal ||
				(this.serieFiscal.serie !== serieFiscal.serie &&
					this.serieFiscal.ambiente !== serieFiscal.ambiente
				),
		)
	}

	editar(indice: number) {
		const serieFiscal = this.seriesFiscais[indice]
		if (!serieFiscal) return
		this.serieFiscal = serieFiscal
		this.mostraDialogo = true
	}

	criar() {
		this.serieFiscal = null
		this.mostraDialogo = true
	}

	salvar(serieFiscal: SerieFiscal) {
		const existeSerie = this.seriesFiscaisSemAtual.some(serieFiscalExistente =>
			serieFiscal.serie  === serieFiscalExistente.serie &&
			serieFiscal.ambiente === serieFiscalExistente.ambiente,
		)
		if (existeSerie) {
			AlertModule.setError("Nº série já existe")
			return
		}

		const indice = this.seriesFiscais.findIndex(serieFiscal =>
			serieFiscal.serie  === this.serieFiscal?.serie &&
			serieFiscal.ambiente === this.serieFiscal?.ambiente,
		)
		indice === -1
			? this.seriesFiscais.push(serieFiscal)
			: this.seriesFiscais.splice(indice, 1, serieFiscal)
	}

	remover(indice: number) {
		const serieFiscal = this.seriesFiscais[indice]
		if (!serieFiscal) return
		const naoTemAssociado = verificarSerieFiscalSemPontoDeVendaAssociado(this.loja?.pdvs || [])(serieFiscal)
		if (naoTemAssociado !== true) return AlertModule.setError(naoTemAssociado)
		this.seriesFiscais.splice(indice, 1)
		this.$emit('input', [
			...this.seriesFiscais,
		])
	}
}

interface DisplaySerieFiscal extends SerieFiscal {
	displaySerie: string
}

