











import { Vue, Component } from 'vue-property-decorator'

@Component
export default class RevealPasswordField extends Vue  {
	mostra = false
}

