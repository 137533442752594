





























import { Vue, Component, Prop, Watch, PropSync } from 'vue-property-decorator'
import { Page, TabelaDePreco, TipoDeCliente } from '@/models'
import { FindTabelaDePrecoUseCase } from '@/usecases'
import axios, { CancelTokenSource } from 'axios'
import { Pageable } from '@/models/Pageable'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
@Component
export default class SeletorDeTabelaDePreco extends Vue {
	@Prop({ type: String }) tipoDeClienteId?: string | null
	@Prop({ type: Boolean, default: () => null }) exibirTodasAsTabelas!: boolean
	@PropSync('value', { type: Object, default: null }) tabelaDePreco!: TabelaDePreco | string | null
	findTabelaDePrecoUseCase = new FindTabelaDePrecoUseCase()
	busca: null | string = null
	pagina: Page<TabelaDePreco> | null = null
	tabelas: TabelaDePreco[] = []
	tabelasCarregadas = 0
	carregouTodasAsTabelas = false
	carregando = false
	cancelToken: CancelTokenSource | null = null
	async created() {
		await this.buscar()
	}
	async buscar(paginavel: Pageable = {}) {
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}
			this.pagina = await this.findTabelaDePrecoUseCase.find({
				nome: this.busca || undefined,
				page: this.busca ? -1 : (paginavel.page || 0),
				size: paginavel.size || 50,
			}, axiosConfig)
			this.pagina.content.forEach(tabela => {
				this.tabelas.push(tabela)
			})
			this.tabelasCarregadas += this.tabelas.length
			this.carregouTodasAsTabelas = this.tabelasCarregadas >= this.pagina.totalElements
		} catch (e) {
			if (axios.isCancel(e)) return
			AlertModule.setError(e)
		} finally {
			this.carregando = false
		}
	}
	get tabelasFormatadas(): TabelaDePreco[] {
		return this.tabelas.map(tabela => ({
			...tabela,
			nome: this.displayNomeTabela(tabela),
		}))
	}
	get tabelasFiltradas() {
		const tabelasFiltradas = this.tipoDeClienteId !== undefined
			? this.tabelasFormatadas.filter(tabela => (tabela.tipoDeCliente && tabela.tipoDeCliente.id) === this.tipoDeClienteId)
			: this.tabelasFormatadas
		const tabelas = this.tabelaDePreco
			? [ ...tabelasFiltradas, this.tabelaDePreco ]
			: [ ...tabelasFiltradas ]
		return tabelasFiltradas.some(tab => tab.id === this.idDaTabelaDePreco)
			? tabelasFiltradas
			: tabelas
	}
	get idDaTabelaDePreco() {
		return typeof this.tabelaDePreco === 'string'
			? this.tabelaDePreco
			: this.tabelaDePreco?.id
	}
	displayNomeTabela(tabela: TabelaDePreco) {
		return tabela.isTabelaDePromocao && !tabela.nome.includes('PROMOÇÃO')
			? `${tabela.nome} - PROMOÇÃO`
			: tabela.nome
	}
	@Watch('tipoDeCliente')
	onChangeTipoDeCliente(tipoAtual: TipoDeCliente | null,
		tipoAnterior: TipoDeCliente | null) {
		if (tipoAtual === tipoAnterior) return
		this.tabelaDePreco = null
		this.busca = ''
		this.buscar()
	}
	@Watch('busca')
	onChangeBusca() {
		this.tabelasCarregadas = 0
		this.tabelas = []
		this.carregouTodasAsTabelas = false
		this.buscar()
	}
	limpaFiltros() {
		this.carregouTodasAsTabelas = false
		this.tabelasCarregadas = 0
	}
}
