













































import { Vue, Component, Prop, PropSync } from 'vue-property-decorator'
import { Usuario, Perfil } from '@/models'
import { Fragment } from 'vue-fragment'
import { VListItemContent } from 'vuetify/lib'
import { UsuariosModule } from '@/store/vuex/usuario/UsuariosStore'
import { FindPerfilUseCase } from '@/usecases'

@Component({
	components: {
		Fragment,
		VListItemContent,
	},
})
export default class ListaDeUsuarios extends Vue {
	@PropSync('value') selecionado!: Usuario | null
	@Prop({ type: Array, default: () => [] }) usuarios!: Usuario[]
	@Prop({ type: Function }) to?: (usuario: Usuario) => void
	perfis: Perfil[] = []

	findPerfilUseCase = new FindPerfilUseCase()

	async created() {
		const perfis = await this.findPerfilUseCase.findAll({})
		UsuariosModule.setPerfis(perfis.content)
		this.perfis = perfis.content
	}

	get mostrarEncurtado() {
		return this.$vuetify.breakpoint.xs || this.$route.name !== 'Usuários'
	}
}
