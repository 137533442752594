









import { Vue, Component, Prop } from 'vue-property-decorator'
import { FormaDePagamento } from '@/models'
import { listaDeFormasDePagamento } from '@/shareds/shared-pagamento'

@Component
export default class SeletorDeFormaDePagamento extends Vue {
	@Prop() mostraPagamentoTEF!: boolean
	@Prop() temTipoPagamentoConsignado!: boolean
	@Prop() temTipoPagamentoDemonstracao!: boolean
	@Prop() temTipoPagamentoValePresente!: boolean

	get formas(): FormaDePagamento[] {
		let formasListadas = listaDeFormasDePagamento

		if (this.temTipoPagamentoConsignado) {
			formasListadas = formasListadas.filter(tipo => tipo !== 'Consignado') 
		}

		if (this.temTipoPagamentoDemonstracao) {
			formasListadas = formasListadas.filter(tipo => tipo !== 'Demonstração') 
		}

		if (this.temTipoPagamentoValePresente) {
			formasListadas = formasListadas.filter(tipo => tipo !== 'Vale Presente') 
		}

		if (this.mostraPagamentoTEF) {
			formasListadas = formasListadas.filter(f => f !== 'TEF Crédito' && f !== 'TEF Débito' && f !== 'TEF Pix')
		}

		return formasListadas
	}
}
