























import { Vue, Component, PropSync, Prop } from 'vue-property-decorator'
import type { SerieFiscal, TipoDeSerieFiscal } from '@/models/SerieFiscal'
import DialogoFormSerieFiscal from './DialogoFormSerieFiscal.vue'
import { verificarSerieRepetida } from '@/shareds/loja-shareds'

@Component({
	components: {
		DialogoFormSerieFiscal,
	},
})
export default class SeletorDeSerieFiscal extends Vue {
	@PropSync('value') serieFiscal!: SerieFiscal
	@Prop() seriesFiscais!: SerieFiscal[]
	@Prop({ type: Boolean, default: false }) podeCriar!: boolean
	@Prop({ type: Array, default: () => ['Venda', 'Devolução'] }) filtros!: TipoDeSerieFiscal[]

	mostrar = false

	get verificarSerieRepetida() {
		return verificarSerieRepetida(this.seriesFiscais)
	}

	get seriesOrdenadas() {
		return [...this.seriesFiscais]
			.filter(serie => this.filtros.includes(serie.tipo))
			.sort((serieA, serieB) => serieA.serie - serieB.serie)
	}

	inserir(serieFiscal: SerieFiscal) {
		this.$emit('novo', serieFiscal)
		this.mostrar = false
	}
}

